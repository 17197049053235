import { FORM_REPAIR_TYPE } from '../enums/enumFromRepair'
import { WORKFLOW_PLACES } from '../enums/enumWorkflowPlaces'
import { PROFILE_FORM_TYPES } from '../enums_v1/enumFormType'
import { iSubmissionDataHlavni, iSubmissionDataReq } from '../interfaces/iEformsSubmission'

export class RepairFormHelper {
  submMain: iSubmissionDataHlavni
  submReq?: iSubmissionDataReq
  formType: string
  editFormData: any

  constructor(submMain: iSubmissionDataHlavni, submReq: iSubmissionDataReq | undefined, editFormData: any) {
    this.submMain = submMain
    this.formType = submMain.data?.druhFormulare
    this.submReq = submReq
    this.editFormData = editFormData
  }

  hasRepariForm() {
    const workflowPlaceCode = this.submMain.workflowPlaceCode
    const isValidPlace = [WORKFLOW_PLACES.UVEREJNENO_VVZ].includes(workflowPlaceCode)
    const repairType = this.getFormTypeToRepair()

    return !!this.formType && isValidPlace && !!repairType
  }

  getFormTypeToRepair(): string | string[] | undefined {
    const repairType = FORM_REPAIR_TYPE[this.formType]
    if (typeof repairType === 'function') {
      return repairType(this.editFormData)
    }
    return repairType
  }

  getDataRepairForm() {
    const isProfil = PROFILE_FORM_TYPES.includes(this.formType)
    const isV1 = !!this.submMain.data.verzeXsd
    const mainData = this.submMain.data

    return {
      needTedNum: mainData.uverejnitTed && !mainData.evCisloTed,
      evCisloZakazky: isProfil ? (this.submMain.data as any).evCisloProfiluVvz : this.submMain.data.evCisloZakazkyVvz,
      evCisloFormulare: this.submMain.variableId,
      kodSouvisFormulare: isV1
        ? this.submReq?.data?.kodProSouvisejiciFormulare
        : this.submReq?.data?.['ND-Root']['BT-002-KodProSouvisejiciFormulare'],
    }
  }
}
